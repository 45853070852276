<template>
  <div v-if="isLoading" class="preLoader">
    <span class="spin"></span>
  </div>

  <header class="header header--sticky">
    <div class="container">
      <div class="header__wrapper">
        <div class="header__left">
          <a href="/">
            <img
              src="img/logo_cloudwell_text_transparent.png"
              class="header__logo"
              alt="雲策股份有限公司"
            />
          </a>
        </div>
        <div class="header__right">
          <nav class="nav">
            <ul class="nav__list">
              <li class="nav__itemwrap">
                <a href="/" class="nav__item nav__item--active">home</a>
              </li>
            </ul>
            <div class="nav__phone">
              <i class="bi bi-telephone-fill"></i>
              <div class="nav__phoneNumber">03-3019789 分機 3228</div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <section id="home" class="home">
    <div class="container">
      <h1>
        Hi, 我們是
        <span>雲策投資</span>
      </h1>
      <p>
        謹防詐騙，這是【雲策投資】正式的官網。<br />【雲策投資】
        不對外提供任何金融理財等投資分析服務。<br />我們更沒有對外提供任何社交媒體(如:Line、FB、IG、WeChat等等)官方帳號。<br />
        理財要小心別陷入詐騙式的金錢陷阱！<br />
        詐騙就是利用人性的貪婪，別讓自己做出錯誤的決策 !
      </p>
    </div>
  </section>

  <section id="announcement" class="announcement">
    <div class="container">
      <div class="heading">
        <h4 class="heading__title">冒名假網站警告</h4>
        <h2 class="heading__subtitle">Scam Alert</h2>
      </div>
      <div class="announcement__imgwrapper">
        <img
          src="/img/pandemicscams-headerimage.jpg"
          class="announcement__image01 mb-3"
          alt="Scam Alert"
        />
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="box">
            <h2 class="box__title">真的</h2>
            <div class="compareBox">
              <img
                class="img-fluid mb-3"
                src="/img/scr_real_cloudwell.png"
                alt="cloudwell"
              />
              <p>URL: https://www.cloudwell.com.tw</p>
              <div class="p-3 mb-3 text-bg-success rounded-3">
                <p>
                  <i class="bi bi-check-circle-fill"></i>
                  瀏覽器認證 - 安全的網址
                </p>
                <p>
                  <i class="bi bi-check-circle-fill"></i>
                  高曼投顧的反詐騙公告 1：
                  <a
                    href="https://gammaparadigm.com.tw/anti-fraud-2023/"
                    target="_blank"
                  >
                    連結
                  </a>
                </p>
                <p>
                  <i class="bi bi-check-circle-fill"></i>
                  高曼投顧的反詐騙公告 2：
                  <a
                    href="https://gammaparadigm.com.tw/%e3%80%90%e5%8f%8d%e8%a9%90%e9%a8%99%e5%85%ac%e5%91%8a%e3%80%91/"
                    target="_blank"
                  >
                    連結
                  </a>
                </p>
                <p>
                  <i class="bi bi-check-circle-fill"></i>
                  不對外提供金融理財等投資分析服務
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="box">
            <h2 class="box__title">假的</h2>
            <div class="compareBox">
              <img
                class="img-fluid mb-3"
                src="/img/scr_fake_cloudwell.png"
                alt="cloudwell"
              />
              <p>URL: https://reportermom.co.kr/</p>
              <p>URL: https://tmappcloud.com/#/</p>
              <div class="p-3 mb-3 text-bg-danger rounded-3">
                <p>
                  <i class="bi bi-x-circle-fill"></i>
                  瀏覽器認證 - 不安全的網址
                </p>
                <p>
                  <i class="bi bi-x-circle-fill"></i>
                  金管編號並無 "一百一十二"
                </p>
                <p>
                  <i class="bi bi-x-circle-fill"></i>
                  聯絡資訊為私人 email 及 私人手機號碼
                </p>
                <p>
                  <i class="bi bi-x-circle-fill"></i>
                  假公司的網站是從
                  <a href="https://gammaparadigm.com.tw/" target="_blank">
                    高曼投顧
                  </a>
                  偷竊而來
                </p>
              </div>

              <div class="ratio ratio-16x9">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/saur6bSIeuE?si=W3eiKSbszfwEToTN"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="container">
      <div class="copyright">
        Copyright © 2024 CloudWell Investment CO., LTD. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script setup>
// @ is an alias to /src
import { ref, onMounted } from "vue";
import { versionControl } from "../central.config";

const version = ref(null);
const isLoading = ref(true);

onMounted(() => {
  version.value = versionControl.core.version;

  setTimeout(() => {
    isLoading.value = false;
  }, 300);
});
</script>

<style scoped lang="scss">
@import "../assets/css/_viewport.scss";
.preLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: #0063f8;
  background: #000000;
  z-index: 99999;
  overflow: hidden;

  .spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
    width: 70px;
    height: 70px;
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.1);
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-radius: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header {
  position: relative;
  background-color: rgba(7, 13, 27, 0.9);
  box-shadow: 0 10px 10px -10px rgba(7, 13, 27, 0.9);
  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 80px;
  }
  &--sticky {
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;
    left: 0;
  }
  &__logo {
    height: 91px;
    @include mobile {
      margin: 12px 5px;
      height: 50px;
    }
  }
}
.nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style: none;
    @include mobile {
      display: none;
    }
  }
  &__itemwrap {
    margin-right: 40px;
  }
  &__item {
    padding: 15px 0;
    font-size: 15px;
    display: inline-block;
    color: #fdfeff;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    cursor: pointer;
    &--active {
      color: #fec544;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    padding-left: 45px;
    position: relative;
    @include mobile {
      padding-left: 0;
    }
    &::before {
      content: "";
      position: absolute;
      height: 11px;
      width: 1px;
      background: #fff;
      left: 0;
      @include mobile {
        display: none;
      }
    }
  }
  &__phoneNumber {
    padding-left: 15px;
    color: #fec544;
    @include mobile {
      font-size: 12px;
    }
  }
}
.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #070d1b;
  background-image: var(--bs-body-bg-cover);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  h1 {
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px;
    @include mobile {
      font-size: 28px;
    }

    span {
      color: #fec544;
    }
  }
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6em;
    margin-bottom: 22px;

    @include mobile {
      font-size: 15px;
      line-height: 2em;
      padding: 0px 37px;
    }
  }
}
.heading {
  text-align: center;
  position: relative;
  margin-top: -5px;
  margin-bottom: 41px;
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fec544;
    position: relative;
    z-index: 10;
    letter-spacing: 1px;
    &::before {
      content: "";
      position: absolute;
      background-color: #3f4551;
      width: 170px;
      height: 2px;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      background-color: #fec544;
      width: 70px;
      height: 2px;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__subtitle {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 90px;
    font-weight: 700;
    color: rgba(21, 27, 41, 0.8);
    margin: -17px 0 0;
    width: 100%;
    @include mobile {
      font-size: 58px;
    }
  }
}
.announcement {
  padding-top: 100px;

  &__imgwrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__image01 {
    width: 250px;
  }
}
.box {
  text-align: center;
  padding: 50px 30px;
  border-radius: 3px;
  transition: all 0.4s ease;
  border: 1px solid #232935;
  background-color: #101624;

  &__title {
    font-weight: 400;
    line-height: 1.2em;
    font-size: 20px;
    margin-bottom: 16px;
    transition: all 0.4s ease;
  }
  &:hover {
    .box__title {
      color: #fec544;
    }
  }
}
.compareBox {
  p {
    margin: 0;
    font-size: 18px;
    line-height: 40px;

    @include mobile {
      font-size: 14px;
    }
  }
}
.copyright {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    font-size: 11px;
  }
}
</style>
