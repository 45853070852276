<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import { special } from "./special";
onMounted(() => {
  document.querySelector("title").textContent = special.title;
});
</script>

<style lang="scss">
:root {
  --bs-body-bg-cover: url("./assets/image/background/hero-bg-1.jpg");
}
body {
  color: #a9adb8;
  background-color: #0a101e;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h6 {
  clear: both;
  color: #fdfeff;
  padding: 0;
  margin: 0 0 10px;
  font-weight: 700;
  line-height: 1.2em;
}
a {
  color: #ffffff;
}
</style>
