require("../core/vendors");
import { createApp } from "vue";
import App from "../core/App.vue";
import "./registerServiceWorker";
import router from "../core/router";
import store from "../core/store";

let app = createApp(App).use(store).use(router);

app.mount("#app");
